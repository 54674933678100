<template>
  <Page title="Account Settings">
    <div class="flex justify-center">
      <div class="container w-1/2">
        <h2>Account settings</h2>
        <br>
        <form class="flex flex-col w-full sm:w-96 mx-auto ">
          <h4>Change Password</h4>
          <Input
            field="current-password"
            inputclass="input--default"
            label="Current Password"
            required
            type="password"
            v-model="currentPassword"
            @keyup.enter="handlePasswordChange"
            @blur="validateExistingPassword()"
            :errors="errors"
          />
          <Input
            field="new-password"
            inputclass="input--default"
            label="New Password"
            required
            type="password"
            v-model="newPassword"
            @keyup.enter="handlePasswordChange"
            @blur="validatePassword(newPassword)"
            :errors="errors"
          />
          <Input
            field="confirm-password"
            inputclass="input--default"
            label="Confirm Password"
            required
            type="password"
            v-model="confirmPassword"
            @keyup.enter="handlePasswordChange"
            @keyup="validatePasswordConfirmation(newPassword)"
            :errors="errors"
          />
          <Button v-if="!errorsExist"
            class="max-w-xs mx-auto"
            @click="handlePasswordChange"
            buttontype="background"
            type="button"
          >
              Reset Password
          </Button>
          <Button v-else
            class="max-w-xs mx-auto"
            buttontype="disabled-border"
            type="button"
            disabled="true"
          >
            Reset Password
          </Button>
        </form>
        <div class="mt-8">
          <h4>Your subscription</h4>
          <a :href="stripe_purchase_subscription_url" v-if="organisation?.max_projects==1" target="_blank">
            <Button
              class="max-w-xs mx-auto"
              buttontype="background"
              type="button"
            >
              Upgrade my account
            </Button>
          </a>
          <a :href="stripe_customer_portal_url" target="_blank" v-else>
            <Button
              class="max-w-xs mx-auto"
              buttontype="border"
              type="button">
              Manage my subscription
            </Button>
          </a>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/page.vue'
import Button from '@/components/button.vue'
import Input from '@/components/input.vue'
import { mapState } from 'vuex'
import { apiRequest, postRequest } from '../components/js/utils'

export default {
  name: 'AccountSettings',
  components: { Page, Button, Input },
  computed: {
    ...mapState({
      user: state => state.user.user,
      organisation: state => state.user.organisation,
      stripe_customer_portal_url: state => state.stripe_customer_portal_url,
      stripe_purchase_subscription_url: state => state.stripe_purchase_subscription_url
    }),
    errorsExist () {
      // flatten errors array and count objects
      return Object.values(this.errors).flat().length > 0
    }
  },
  data () {
    return {
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      errors: {}
    }
  },
  methods: {
    validateExistingPassword () {
      if (!this.currentPassword) {
        this.errors['current-password'] = ['Please enter your current password']
      } else {
        this.errors['current-password'] = []
      }
    },
    validatePassword (password) {
      this.validateExistingPassword()
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/
      if (!regex.test(password)) {
        this.errors['new-password'] = [
          'Password must be at least 8 characters long, include an' +
          ' uppercase letter, a lowercase letter and a number'
        ]
      } else {
        this.errors['new-password'] = []
      }
    },
    validatePasswordConfirmation (newPassword) {
      if (this.confirmPassword !== newPassword) {
        this.errors['confirm-password'] = ['Passwords do not match']
      } else {
        this.errors['confirm-password'] = []
      }
    },
    async handlePasswordChange () {
      await apiRequest.get('get-csrf/')

      if (!this.currentPassword || !this.newPassword || !this.confirmPassword) {
        this.$store.commit('addAlert', {
          status: 'error',
          message: 'Please fill in all fields',
          timer: 3000
        })
        return
      }

      try {
        postRequest('change-password', {
          old_password: this.currentPassword,
          new_password: this.newPassword,
          confirm_password: this.confirmPassword
        }, (res) => {
          // success callback
          this.$store.commit('addAlert', {
            status: 'success',
            message: 'Your password has been updated. Please log back in.',
            timer: 3000
          })
          setTimeout(() => {
            window.location.href = '/'
          }, 2000)
        }, (res) => {
          // error callback
          if (res.response.data.error === 'Invalid old password') {
            this.$store.commit('addAlert', {
              status: 'error',
              message: 'The current password you entered is incorrect',
              timer: 3000
            })
          } else if (res.response.data.error === 'New password and confirm password do not match') {
            this.$store.commit('addAlert', {
              status: 'error',
              message: 'The new passwords you entered do not match, please try again',
              timer: 3000
            })
          } else {
            this.$store.commit('addAlert', {
              status: 'error',
              message: 'An error occured whilst attempting to reset ' +
               'your password. Please try again',
              timer: 3000
            })
          }
        })
      } catch (e) {
        this.$store.commit('addAlert', {
          status: 'error',
          message: 'An error occured whilst attempting to reset ' +
           'your password. Please try again',
          timer: 3000
        })
      }
    }
  },
  created () {
    if (!this.user) { this.$store.dispatch('getUser') }
    this.$store.dispatch('getStripeUrls')
    this.$store.dispatch('getOrganisation')
  }
}
</script>
